import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Button } from 'reactstrap';
import './Navigation.scss';
import cookie from '../../../helpers/cookieHelper';
import Image from '../../../components/Image/Image';
import config from '../../../config';
import { AlpLogo } from '../../../helpers/imagePreloader';
import { menuItems } from '../Utils/constants';

function Navigation() {
  const [dropdownOpened, setDropdownOpened] = useState('');
  const [menuOpen, setMenuOpen] = useState(false);

  const getCarretIcon = (dropdownId: string) => {
    return dropdownOpened === dropdownId ? (
      <i className="icon icon-chevron-up nav-dropdown-icon" />
    ) : (
      <i className="icon icon-chevron-down nav-dropdown-icon" />
    );
  };

  const toggleMenu = (e: any) => {
    e.preventDefault();
    setMenuOpen(!menuOpen);
  };

  const getMenu = (): JSX.Element[] => {
    return menuItems.map((item: any, key: number) => {
        if ((!item.isProtected && !cookie.isLoggedIn()) || (!item.hideAfterLogin && cookie.isLoggedIn())) {
          return (
            <li
              key={key}
              className={item.displayName.length > 40 ? 'w-shrink' : item.displayName.length > 22 ? 'w-shrink-2' : 'no-shrink' + (item.className ?? '')}
            >
              {!item.dropdownId ? (
                <NavLink
                  to={item.urlPath}
                  target={item.linkToNewTab ? '_blank' : ''}
                  onClick={() => setDropdownOpened('')}
                  className={item.className}
                  end
                >
                   <span dangerouslySetInnerHTML={{ __html: item.displayName }} />
                  <div className="selected-underline" />
                </NavLink>
              ) : (
                // if the menu is a dropdown, we use div instead of navlink to prevent redirect and just show the submenu
                <div
                  className="main-menu-dropdownmenu"
                  onClick={() => setDropdownOpened((state) => (state === item.dropdownId ? '' : item.dropdownId))}
                >
                  <span>{item.displayName}</span>

                  {getCarretIcon(item.dropdownId)}
                </div>
              )}

              <div className="selected-underline" />
            </li>
          );
        } else {
          return <React.Fragment key={key} />;
        }
      });
  };

  return (
    <header className={`main-header flex flex-col ${menuOpen ? 'open' : ''}`}>
    <div className={`navigation-row`}>
      <nav className="flex flex-row ">
        <div className="brand-col-wrapper flex flex-row">
          <div className="brand-col">
            <a href="/">
              <Image src={AlpLogo} alt="A Learning Place Logo" />
            </a>
          </div>
          <a href='#' className="hamburger" onClick={toggleMenu}>
            &#9776;
          </a>
          <div className="flex flex-row flex_1 gap1">
            <div className="flex flex-row flex_1">
              <ul className={`menu-col flex flex-row ${menuOpen ? 'open' : ''}`}>{getMenu()} </ul>
            </div>
            <div className="buttons-col flex flex-row">
              <a href={`${config.subsiteUrls.main}/login/redirect/tr`}>
                <Button color="link" className="login-btn">
                  LOGIN
                </Button>
              </a>

              <a href={`${config.subsiteUrls.main}/createaccount`}>
              <Button className="create-btn">Create A Free Account</Button>
              </a>
            </div>
          </div>
        </div>
      </nav>
    </div>
    <div className={`subsite-row flex with-shadow`}></div>
  </header>
  );
}

export default Navigation;
